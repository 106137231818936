

import { Icon } from '@iconify/react';
import React from 'react';


const Eye = () => {
  return (
    <Icon icon="iconamoon:eye-off-light" />
  )
}

export default Eye;
