import { Icon } from '@iconify/react';
import React from 'react';


const Eye = () => {
  return (
    <Icon icon="simple-line-icons:eye" />
  )
}

export default Eye;
