import axios from "axios";

// dashboard url
export const dashboardUrl = "https://mydoctorsdashboard.gitdr.com/";

// image base url
export const imageBase_URL =
  "https://doctorbackend.gitdr.com/";

  export const BASIC_URL = "https://doctorbackend.gitdr.com/api";


export const axiosApi = axios.create({
  baseURL: BASIC_URL,
  headers: {
    "Content-Type": "application/json",
    'ngrok-skip-browser-warning': 'true'
  },
});

axiosApi.interceptors.request.use(function (config) {
  const token = sessionStorage.getItem("accessToken");
  if (token) {
    config.headers["Authorization"] = "bearer " + token;
  }
  return config;
});


